.carousel_container {
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}


/* If you want to use a custom scrolling bar */

/* @media only screen and (min-width: 768px) {
  .carousel_container::-webkit-scrollbar {
    width: 4px; 
    height: 17px;
  }
  .carousel_container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.192); 
    border-radius: 30px;
  }
  .carousel_container::-webkit-scrollbar-thumb {
    background-color: rgb(23, 40, 48);
    border-radius: 20px; 
    padding: 20px;              
  }
} */


.swiper_container {
  height: 500px;
  background-color: red;
}