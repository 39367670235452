/* .swiper-button-prev,
.swiper-button-next {
  top: 42%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  padding: 30px;
  background-color: aliceblue;
  border-radius: 50%;
}

.swiper-button-prev {
}
.swiper-button-prev::after {
}

.swiper-button-next {

}
.swiper-button-next::after {
} */

.swiper-scrollbar-drag {
  /* background: linear-gradient(to left, #b2fefa, #0ed2f7); */
  background: linear-gradient(to right, #67fff7, #0fadcc);
}
.swiper-container-horizontal > .swiper-scrollbar {
  width: 95%;
  left: 3%;
}
