/* *, 
*::before, 
*::after{
outline:1px solid red;
} */

.App {
  box-sizing: border-box;
  /* font-family: 'Poppins', sans-serif;
  font-weight: 900; */
}

.calc_minHeight {
  display: block;
  /* Pages are using mt-4 which is margin-top 24 px in general. So 116+24 */
  min-height: calc(100vh - 140px);
}

.avatar_size {
  width: 75px;
  height: 75px;
}
.height_adjust {
  display: flex;
  min-height: calc(100vh - 116px);
  flex-direction: column;
  justify-content: space-between;
}
