.genres {
  overflow: auto;
  white-space: nowrap;
  width: 68%;
  /* height: 30px; */
}

.genres_box {
  border: 1px solid white;
  border-radius: 20px;
}

.rating_box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 10px;
  font-size: 13px;
}

.favourite_box {
  padding-top: 1px;
}

.icon_color_blue {
  color: #0ed2f7;
}
.icon_spacing {
  margin-top: 3px;
}

.cstm_downlink {
  padding: 0px;
  font-size: 16px;
  border: 1px solid rgb(24, 22, 22);
  border-radius: 30px;
  background-color: #0f131a;
  -webkit-box-shadow: 10px 8px 6px -6px rgba(0, 0, 0, 0.75),
    -3px -3px 11px -6px rgba(105, 105, 105, 0.5);
  -moz-box-shadow: 10px 8px 6px -6px rgba(0, 0, 0, 0.75),
    -3px -3px 11px -6px rgba(105, 105, 105, 0.5);
  box-shadow: 8px 8px 6px -6px rgba(0, 0, 0, 0.75),
    -3px -3px 11px -6px rgba(105, 105, 105, 0.5);

  position: relative;
}

.new_item {
  position: absolute;
  top: 0;
  right: 5;
  margin-left: 5px;
  margin-top: 5px;
  border: 1px solid red;
  background-color: #f04f43;
  border-radius: 50%;
  padding: 3px;
}

.cstm_downlink:active {
  box-shadow: rgba(0, 0, 0, 0.5) 12px 12px 24px 0px inset,
    rgba(58, 58, 58, 0.376) -12px -12px 24px 0px inset;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 12px 12px 24px 0px inset,
    rgba(58, 58, 58, 0.376) -12px -12px 24px 0px inset;
}

.cstm_downlink:visited {
  box-shadow: rgba(0, 0, 0, 0.5) 12px 12px 24px 0px inset,
    rgba(58, 58, 58, 0.376) -12px -12px 24px 0px inset;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 12px 12px 24px 0px inset,
    rgba(58, 58, 58, 0.376) -12px -12px 24px 0px inset;
}

.cstm_downlink:focus {
  box-shadow: rgba(0, 0, 0, 0.5) 12px 12px 24px 0px inset,
    rgba(58, 58, 58, 0.376) -12px -12px 24px 0px inset;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 12px 12px 24px 0px inset,
    rgba(58, 58, 58, 0.376) -12px -12px 24px 0px inset;
}
.cstm_downlink_group {
  min-height: 200px;
}

.casts_img {
  overflow: auto;
  white-space: nowrap;

  /* height: 30px; */
}

.DetailsPage .rs-panel-heading,
.rs-panel-body {
  font-size: 16px !important;
  padding-bottom: 5px;
}

.rs-panel-heading {
  font-size: 20px !important;
  padding: 0.4rem !important;
}

.rs-panel-heading .rs-panel-title {
  font-size: 20px !important;
  padding-left: 2px;
}

.rs-panel-collapsible > .rs-panel-heading::before {
  position: absolute;
  font-family: "rsuite-icon-font";
  content: "\ea08";
  top: 10px;
  right: 20px;
  -webkit-transition: -webkit-transform 0.3s linear;
  transition: -webkit-transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear;
}
