.text_gradient {
  background-color: #3fd4cd;
  background-color: linear-gradient(to left, #b2fefa, #0ed2f7);
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.HomePage .rs-btn-ghost {
  border: none !important;
}

.btn_font_style {
  font-family: "Concert One", cursive;
  letter-spacing: 0.2px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.747);
  align-self: flex-end;
  margin-bottom: 2px;
  cursor: pointer;
}
.intro_heading_text {
  font-family: "Concert One", cursive;
  font-size: 25px;
}

.intro_heading_text2 {
  font-family: "Concert One", cursive;
  font-size: 18px;
  white-space: nowrap;
}

.svg_div {
  width: 50%;
  height: auto;
}
.svg_side_text {
  font: 20px;
  /* font-family: 'Concert One', cursive; */
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  flex: 1;
}
.content_heading_text {
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  white-space: nowrap;
}

.get_started_btn {
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.span_text {
  font-family: "Roboto", sans-serif;
  background-color: rgb(108, 23, 177);
  padding: 3px;
  border-radius: 7px;
}

.view_all_btn {
  border-radius: 30px !important;
}


.font_poppins {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
}

.explore_section {
  /* background-color: rgb(25,18,96); */
  background: linear-gradient(180deg, rgba(15,19,26,1) 39%, rgba(20,16,93,1) 100%);
}
