.custom_loading {
   position: fixed;
   top: 45%;
   left: 40%;
}


.custom_loading > ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.custom_loading > ul > li {
  list-style: none;
  color: #e0e4eb;
  font-size: 40px;
  letter-spacing: 8px;
  animation: lighting 1.2s linear infinite;
}

@keyframes lighting {
  0% {
    color: #0f131a;
    text-shadow: none;
  }
  90% {
    color: #0f131a;
    text-shadow: none;
  }
  100% {
    color: #0f131a;
    text-shadow: 0 0 7px #ffffff, 0 0 50px #ffffff;
  }
}

.custom_loading > ul li:nth-child(1) {
  animation-delay: 0s;
}
.custom_loading > ul li:nth-child(2) {
  animation-delay: 0.1s;
}

.custom_loading > ul li:nth-child(3) {
  animation-delay: 0.2;
}

.custom_loading > ul li:nth-child(4) {
  animation-delay: 0.3s;
}

.custom_loading > ul li:nth-child(5) {
  animation-delay: 0.4s;
}
.custom_loading > ul li:nth-child(6) {
  animation-delay: 0.5s;
}
.custom_loading > ul li:nth-child(7) {
  animation-delay: 0.6s;
}
