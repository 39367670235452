.NavBar {
  height: 56px;
  background-color: #1a1d24;

  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

/* .pushCenter {
  position: absolute;
  left: 50%;
  margin-left: -9.2px !important;  50% of your logo width
  display: block;
} */

.NavBar .rs-btn-ghost {
   border: none !important;
}
