.FooterPage {
  /* font-family: 'Concert One', cursive; */
  /* font-family: 'Roboto', sans-serif; */
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  display: block;
  height: 60px;
  width: 100%;
  background-color: #0f1218;
  box-shadow: -2px -2px 10px -6px rgba(105, 105, 105, 0.5);
 
}

.FooterPage_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* .copyright {
  font-family: "Concert One", cursive;
  font-weight: lighter;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.7);
} */

.copy_sign {
  font-family: "Roboto", sans-serif;
  color: rgba(255, 255, 255, 0.7);
}

.social_icons_group {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media only screen and (max-width: 769px) {
  .FooterPage {
    font-size: 14px;
  }

  .social_icons_group > * {
    margin: 0 6px;
  }
}
