.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  cursor: pointer;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  cursor: pointer;
}

@media only screen and (min-width: 570px) {
  .video-responsive {
   
    padding-bottom: 0;
    display: block;
    height: 50vh;
    max-height: 50vh;
  }


}

@media only screen and (max-width: 570px) {
  .container-md {
  width: 100%;
   padding-right: 0;
   padding-left:0;
   margin-right: 0;
   margin-left: 0;
  }
}
 
