@import url(https://fonts.googleapis.com/css2?family=Concert+One&family=Poppins:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Londrina+Shadow&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Otomanopee+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
/* @import "~rsuite/dist/styles/rsuite-default.css"; */

/* *, 
*::before, 
*::after{
outline:1px solid red;
} */

.App {
  box-sizing: border-box;
  /* font-family: 'Poppins', sans-serif;
  font-weight: 900; */
}

.calc_minHeight {
  display: block;
  /* Pages are using mt-4 which is margin-top 24 px in general. So 116+24 */
  min-height: calc(100vh - 140px);
}

.avatar_size {
  width: 75px;
  height: 75px;
}
.height_adjust {
  display: flex;
  min-height: calc(100vh - 116px);
  flex-direction: column;
  justify-content: space-between;
}

.CourseCard {
  width: 40%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  border: 1px solid #eeeeff;
  border-radius: 8px;
  overflow: hidden;
}

.img_container {
  width: 40px;
  height: 40px;
}

/* .view_all_btn {
   display: block;
   margin-top: -24px;
 }
  */

.rs-drawer-header .rs-drawer-header-close {
  color: #bd1c00 !important;
  font-size: 16px !important;
}

.NavBar {
  height: 56px;
  background-color: #1a1d24;

  position: -webkit-sticky;

  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

/* .pushCenter {
  position: absolute;
  left: 50%;
  margin-left: -9.2px !important;  50% of your logo width
  display: block;
} */

.NavBar .rs-btn-ghost {
   border: none !important;
}

.card_body {
  text-align: center;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: rgba(8, 8, 8, 0.753);
  /* background-color: #22252b; */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.image_panel {
  position: relative;
  display: inline-block;
  width: 148px;
  border: 0;
}

.add_to_fav {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(54, 54, 54, 0.527);
  border-radius: 0px 6px 0px 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.MovieCard {
  width: 100%;
  height: auto;
}


/*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

/* float styles */

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

/* overflow */

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

/* position */

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

/* shadow */

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

/* width */

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

/* height */

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

/* max-/min- width/height */

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

/* text-align */

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

/* font-weight */

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

/*!
 * Bootstrap Grid v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
 html {
   box-sizing: border-box;
   -ms-overflow-style: scrollbar;
 }
 
 *,
 *::before,
 *::after {
   box-sizing: inherit;
 }
 
 .container,
 .container-fluid,
 .container-sm,
 .container-md,
 .container-lg,
 .container-xl {
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
   margin-right: auto;
   margin-left: auto;
 }
 
 @media (min-width: 576px) {
   .container,
   .container-sm {
     max-width: 540px;
   }
 }
 
 @media (min-width: 768px) {
   .container,
   .container-sm,
   .container-md {
     max-width: 720px;
   }
 }
 
 @media (min-width: 992px) {
   .container,
   .container-sm,
   .container-md,
   .container-lg {
     max-width: 960px;
   }
 }
 
 @media (min-width: 1200px) {
   .container,
   .container-sm,
   .container-md,
   .container-lg,
   .container-xl {
     max-width: 1140px;
   }
 }
 
 .row {
   display: flex;
   flex-wrap: wrap;
   margin-right: -15px;
   margin-left: -15px;
 }
 
 .no-gutters {
   margin-right: 0;
   margin-left: 0;
 }
 
 .no-gutters > .col,
 .no-gutters > [class*="col-"] {
   padding-right: 0;
   padding-left: 0;
 }
 
 .col-1,
 .col-2,
 .col-3,
 .col-4,
 .col-5,
 .col-6,
 .col-7,
 .col-8,
 .col-9,
 .col-10,
 .col-11,
 .col-12,
 .col,
 .col-auto,
 .col-sm-1,
 .col-sm-2,
 .col-sm-3,
 .col-sm-4,
 .col-sm-5,
 .col-sm-6,
 .col-sm-7,
 .col-sm-8,
 .col-sm-9,
 .col-sm-10,
 .col-sm-11,
 .col-sm-12,
 .col-sm,
 .col-sm-auto,
 .col-md-1,
 .col-md-2,
 .col-md-3,
 .col-md-4,
 .col-md-5,
 .col-md-6,
 .col-md-7,
 .col-md-8,
 .col-md-9,
 .col-md-10,
 .col-md-11,
 .col-md-12,
 .col-md,
 .col-md-auto,
 .col-lg-1,
 .col-lg-2,
 .col-lg-3,
 .col-lg-4,
 .col-lg-5,
 .col-lg-6,
 .col-lg-7,
 .col-lg-8,
 .col-lg-9,
 .col-lg-10,
 .col-lg-11,
 .col-lg-12,
 .col-lg,
 .col-lg-auto,
 .col-xl-1,
 .col-xl-2,
 .col-xl-3,
 .col-xl-4,
 .col-xl-5,
 .col-xl-6,
 .col-xl-7,
 .col-xl-8,
 .col-xl-9,
 .col-xl-10,
 .col-xl-11,
 .col-xl-12,
 .col-xl,
 .col-xl-auto {
   position: relative;
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
 }
 
 .col {
   flex-basis: 0;
   flex-grow: 1;
   max-width: 100%;
 }
 
 .row-cols-1 > * {
   flex: 0 0 100%;
   max-width: 100%;
 }
 
 .row-cols-2 > * {
   flex: 0 0 50%;
   max-width: 50%;
 }
 
 .row-cols-3 > * {
   flex: 0 0 33.333333%;
   max-width: 33.333333%;
 }
 
 .row-cols-4 > * {
   flex: 0 0 25%;
   max-width: 25%;
 }
 
 .row-cols-5 > * {
   flex: 0 0 20%;
   max-width: 20%;
 }
 
 .row-cols-6 > * {
   flex: 0 0 16.666667%;
   max-width: 16.666667%;
 }
 
 .col-auto {
   flex: 0 0 auto;
   width: auto;
   max-width: 100%;
 }
 
 .col-1 {
   flex: 0 0 8.333333%;
   max-width: 8.333333%;
 }
 
 .col-2 {
   flex: 0 0 16.666667%;
   max-width: 16.666667%;
 }
 
 .col-3 {
   flex: 0 0 25%;
   max-width: 25%;
 }
 
 .col-4 {
   flex: 0 0 33.333333%;
   max-width: 33.333333%;
 }
 
 .col-5 {
   flex: 0 0 41.666667%;
   max-width: 41.666667%;
 }
 
 .col-6 {
   flex: 0 0 50%;
   max-width: 50%;
 }
 
 .col-7 {
   flex: 0 0 58.333333%;
   max-width: 58.333333%;
 }
 
 .col-8 {
   flex: 0 0 66.666667%;
   max-width: 66.666667%;
 }
 
 .col-9 {
   flex: 0 0 75%;
   max-width: 75%;
 }
 
 .col-10 {
   flex: 0 0 83.333333%;
   max-width: 83.333333%;
 }
 
 .col-11 {
   flex: 0 0 91.666667%;
   max-width: 91.666667%;
 }
 
 .col-12 {
   flex: 0 0 100%;
   max-width: 100%;
 }
 
 .order-first {
   order: -1;
 }
 
 .order-last {
   order: 13;
 }
 
 .order-0 {
   order: 0;
 }
 
 .order-1 {
   order: 1;
 }
 
 .order-2 {
   order: 2;
 }
 
 .order-3 {
   order: 3;
 }
 
 .order-4 {
   order: 4;
 }
 
 .order-5 {
   order: 5;
 }
 
 .order-6 {
   order: 6;
 }
 
 .order-7 {
   order: 7;
 }
 
 .order-8 {
   order: 8;
 }
 
 .order-9 {
   order: 9;
 }
 
 .order-10 {
   order: 10;
 }
 
 .order-11 {
   order: 11;
 }
 
 .order-12 {
   order: 12;
 }
 
 .offset-1 {
   margin-left: 8.333333%;
 }
 
 .offset-2 {
   margin-left: 16.666667%;
 }
 
 .offset-3 {
   margin-left: 25%;
 }
 
 .offset-4 {
   margin-left: 33.333333%;
 }
 
 .offset-5 {
   margin-left: 41.666667%;
 }
 
 .offset-6 {
   margin-left: 50%;
 }
 
 .offset-7 {
   margin-left: 58.333333%;
 }
 
 .offset-8 {
   margin-left: 66.666667%;
 }
 
 .offset-9 {
   margin-left: 75%;
 }
 
 .offset-10 {
   margin-left: 83.333333%;
 }
 
 .offset-11 {
   margin-left: 91.666667%;
 }
 
 @media (min-width: 576px) {
   .col-sm {
     flex-basis: 0;
     flex-grow: 1;
     max-width: 100%;
   }
   .row-cols-sm-1 > * {
     flex: 0 0 100%;
     max-width: 100%;
   }
   .row-cols-sm-2 > * {
     flex: 0 0 50%;
     max-width: 50%;
   }
   .row-cols-sm-3 > * {
     flex: 0 0 33.333333%;
     max-width: 33.333333%;
   }
   .row-cols-sm-4 > * {
     flex: 0 0 25%;
     max-width: 25%;
   }
   .row-cols-sm-5 > * {
     flex: 0 0 20%;
     max-width: 20%;
   }
   .row-cols-sm-6 > * {
     flex: 0 0 16.666667%;
     max-width: 16.666667%;
   }
   .col-sm-auto {
     flex: 0 0 auto;
     width: auto;
     max-width: 100%;
   }
   .col-sm-1 {
     flex: 0 0 8.333333%;
     max-width: 8.333333%;
   }
   .col-sm-2 {
     flex: 0 0 16.666667%;
     max-width: 16.666667%;
   }
   .col-sm-3 {
     flex: 0 0 25%;
     max-width: 25%;
   }
   .col-sm-4 {
     flex: 0 0 33.333333%;
     max-width: 33.333333%;
   }
   .col-sm-5 {
     flex: 0 0 41.666667%;
     max-width: 41.666667%;
   }
   .col-sm-6 {
     flex: 0 0 50%;
     max-width: 50%;
   }
   .col-sm-7 {
     flex: 0 0 58.333333%;
     max-width: 58.333333%;
   }
   .col-sm-8 {
     flex: 0 0 66.666667%;
     max-width: 66.666667%;
   }
   .col-sm-9 {
     flex: 0 0 75%;
     max-width: 75%;
   }
   .col-sm-10 {
     flex: 0 0 83.333333%;
     max-width: 83.333333%;
   }
   .col-sm-11 {
     flex: 0 0 91.666667%;
     max-width: 91.666667%;
   }
   .col-sm-12 {
     flex: 0 0 100%;
     max-width: 100%;
   }
   .order-sm-first {
     order: -1;
   }
   .order-sm-last {
     order: 13;
   }
   .order-sm-0 {
     order: 0;
   }
   .order-sm-1 {
     order: 1;
   }
   .order-sm-2 {
     order: 2;
   }
   .order-sm-3 {
     order: 3;
   }
   .order-sm-4 {
     order: 4;
   }
   .order-sm-5 {
     order: 5;
   }
   .order-sm-6 {
     order: 6;
   }
   .order-sm-7 {
     order: 7;
   }
   .order-sm-8 {
     order: 8;
   }
   .order-sm-9 {
     order: 9;
   }
   .order-sm-10 {
     order: 10;
   }
   .order-sm-11 {
     order: 11;
   }
   .order-sm-12 {
     order: 12;
   }
   .offset-sm-0 {
     margin-left: 0;
   }
   .offset-sm-1 {
     margin-left: 8.333333%;
   }
   .offset-sm-2 {
     margin-left: 16.666667%;
   }
   .offset-sm-3 {
     margin-left: 25%;
   }
   .offset-sm-4 {
     margin-left: 33.333333%;
   }
   .offset-sm-5 {
     margin-left: 41.666667%;
   }
   .offset-sm-6 {
     margin-left: 50%;
   }
   .offset-sm-7 {
     margin-left: 58.333333%;
   }
   .offset-sm-8 {
     margin-left: 66.666667%;
   }
   .offset-sm-9 {
     margin-left: 75%;
   }
   .offset-sm-10 {
     margin-left: 83.333333%;
   }
   .offset-sm-11 {
     margin-left: 91.666667%;
   }
 }
 
 @media (min-width: 768px) {
   .col-md {
     flex-basis: 0;
     flex-grow: 1;
     max-width: 100%;
   }
   .row-cols-md-1 > * {
     flex: 0 0 100%;
     max-width: 100%;
   }
   .row-cols-md-2 > * {
     flex: 0 0 50%;
     max-width: 50%;
   }
   .row-cols-md-3 > * {
     flex: 0 0 33.333333%;
     max-width: 33.333333%;
   }
   .row-cols-md-4 > * {
     flex: 0 0 25%;
     max-width: 25%;
   }
   .row-cols-md-5 > * {
     flex: 0 0 20%;
     max-width: 20%;
   }
   .row-cols-md-6 > * {
     flex: 0 0 16.666667%;
     max-width: 16.666667%;
   }
   .col-md-auto {
     flex: 0 0 auto;
     width: auto;
     max-width: 100%;
   }
   .col-md-1 {
     flex: 0 0 8.333333%;
     max-width: 8.333333%;
   }
   .col-md-2 {
     flex: 0 0 16.666667%;
     max-width: 16.666667%;
   }
   .col-md-3 {
     flex: 0 0 25%;
     max-width: 25%;
   }
   .col-md-4 {
     flex: 0 0 33.333333%;
     max-width: 33.333333%;
   }
   .col-md-5 {
     flex: 0 0 41.666667%;
     max-width: 41.666667%;
   }
   .col-md-6 {
     flex: 0 0 50%;
     max-width: 50%;
   }
   .col-md-7 {
     flex: 0 0 58.333333%;
     max-width: 58.333333%;
   }
   .col-md-8 {
     flex: 0 0 66.666667%;
     max-width: 66.666667%;
   }
   .col-md-9 {
     flex: 0 0 75%;
     max-width: 75%;
   }
   .col-md-10 {
     flex: 0 0 83.333333%;
     max-width: 83.333333%;
   }
   .col-md-11 {
     flex: 0 0 91.666667%;
     max-width: 91.666667%;
   }
   .col-md-12 {
     flex: 0 0 100%;
     max-width: 100%;
   }
   .order-md-first {
     order: -1;
   }
   .order-md-last {
     order: 13;
   }
   .order-md-0 {
     order: 0;
   }
   .order-md-1 {
     order: 1;
   }
   .order-md-2 {
     order: 2;
   }
   .order-md-3 {
     order: 3;
   }
   .order-md-4 {
     order: 4;
   }
   .order-md-5 {
     order: 5;
   }
   .order-md-6 {
     order: 6;
   }
   .order-md-7 {
     order: 7;
   }
   .order-md-8 {
     order: 8;
   }
   .order-md-9 {
     order: 9;
   }
   .order-md-10 {
     order: 10;
   }
   .order-md-11 {
     order: 11;
   }
   .order-md-12 {
     order: 12;
   }
   .offset-md-0 {
     margin-left: 0;
   }
   .offset-md-1 {
     margin-left: 8.333333%;
   }
   .offset-md-2 {
     margin-left: 16.666667%;
   }
   .offset-md-3 {
     margin-left: 25%;
   }
   .offset-md-4 {
     margin-left: 33.333333%;
   }
   .offset-md-5 {
     margin-left: 41.666667%;
   }
   .offset-md-6 {
     margin-left: 50%;
   }
   .offset-md-7 {
     margin-left: 58.333333%;
   }
   .offset-md-8 {
     margin-left: 66.666667%;
   }
   .offset-md-9 {
     margin-left: 75%;
   }
   .offset-md-10 {
     margin-left: 83.333333%;
   }
   .offset-md-11 {
     margin-left: 91.666667%;
   }
 }
 
 @media (min-width: 992px) {
   .col-lg {
     flex-basis: 0;
     flex-grow: 1;
     max-width: 100%;
   }
   .row-cols-lg-1 > * {
     flex: 0 0 100%;
     max-width: 100%;
   }
   .row-cols-lg-2 > * {
     flex: 0 0 50%;
     max-width: 50%;
   }
   .row-cols-lg-3 > * {
     flex: 0 0 33.333333%;
     max-width: 33.333333%;
   }
   .row-cols-lg-4 > * {
     flex: 0 0 25%;
     max-width: 25%;
   }
   .row-cols-lg-5 > * {
     flex: 0 0 20%;
     max-width: 20%;
   }
   .row-cols-lg-6 > * {
     flex: 0 0 16.666667%;
     max-width: 16.666667%;
   }
   .col-lg-auto {
     flex: 0 0 auto;
     width: auto;
     max-width: 100%;
   }
   .col-lg-1 {
     flex: 0 0 8.333333%;
     max-width: 8.333333%;
   }
   .col-lg-2 {
     flex: 0 0 16.666667%;
     max-width: 16.666667%;
   }
   .col-lg-3 {
     flex: 0 0 25%;
     max-width: 25%;
   }
   .col-lg-4 {
     flex: 0 0 33.333333%;
     max-width: 33.333333%;
   }
   .col-lg-5 {
     flex: 0 0 41.666667%;
     max-width: 41.666667%;
   }
   .col-lg-6 {
     flex: 0 0 50%;
     max-width: 50%;
   }
   .col-lg-7 {
     flex: 0 0 58.333333%;
     max-width: 58.333333%;
   }
   .col-lg-8 {
     flex: 0 0 66.666667%;
     max-width: 66.666667%;
   }
   .col-lg-9 {
     flex: 0 0 75%;
     max-width: 75%;
   }
   .col-lg-10 {
     flex: 0 0 83.333333%;
     max-width: 83.333333%;
   }
   .col-lg-11 {
     flex: 0 0 91.666667%;
     max-width: 91.666667%;
   }
   .col-lg-12 {
     flex: 0 0 100%;
     max-width: 100%;
   }
   .order-lg-first {
     order: -1;
   }
   .order-lg-last {
     order: 13;
   }
   .order-lg-0 {
     order: 0;
   }
   .order-lg-1 {
     order: 1;
   }
   .order-lg-2 {
     order: 2;
   }
   .order-lg-3 {
     order: 3;
   }
   .order-lg-4 {
     order: 4;
   }
   .order-lg-5 {
     order: 5;
   }
   .order-lg-6 {
     order: 6;
   }
   .order-lg-7 {
     order: 7;
   }
   .order-lg-8 {
     order: 8;
   }
   .order-lg-9 {
     order: 9;
   }
   .order-lg-10 {
     order: 10;
   }
   .order-lg-11 {
     order: 11;
   }
   .order-lg-12 {
     order: 12;
   }
   .offset-lg-0 {
     margin-left: 0;
   }
   .offset-lg-1 {
     margin-left: 8.333333%;
   }
   .offset-lg-2 {
     margin-left: 16.666667%;
   }
   .offset-lg-3 {
     margin-left: 25%;
   }
   .offset-lg-4 {
     margin-left: 33.333333%;
   }
   .offset-lg-5 {
     margin-left: 41.666667%;
   }
   .offset-lg-6 {
     margin-left: 50%;
   }
   .offset-lg-7 {
     margin-left: 58.333333%;
   }
   .offset-lg-8 {
     margin-left: 66.666667%;
   }
   .offset-lg-9 {
     margin-left: 75%;
   }
   .offset-lg-10 {
     margin-left: 83.333333%;
   }
   .offset-lg-11 {
     margin-left: 91.666667%;
   }
 }
 
 @media (min-width: 1200px) {
   .col-xl {
     flex-basis: 0;
     flex-grow: 1;
     max-width: 100%;
   }
   .row-cols-xl-1 > * {
     flex: 0 0 100%;
     max-width: 100%;
   }
   .row-cols-xl-2 > * {
     flex: 0 0 50%;
     max-width: 50%;
   }
   .row-cols-xl-3 > * {
     flex: 0 0 33.333333%;
     max-width: 33.333333%;
   }
   .row-cols-xl-4 > * {
     flex: 0 0 25%;
     max-width: 25%;
   }
   .row-cols-xl-5 > * {
     flex: 0 0 20%;
     max-width: 20%;
   }
   .row-cols-xl-6 > * {
     flex: 0 0 16.666667%;
     max-width: 16.666667%;
   }
   .col-xl-auto {
     flex: 0 0 auto;
     width: auto;
     max-width: 100%;
   }
   .col-xl-1 {
     flex: 0 0 8.333333%;
     max-width: 8.333333%;
   }
   .col-xl-2 {
     flex: 0 0 16.666667%;
     max-width: 16.666667%;
   }
   .col-xl-3 {
     flex: 0 0 25%;
     max-width: 25%;
   }
   .col-xl-4 {
     flex: 0 0 33.333333%;
     max-width: 33.333333%;
   }
   .col-xl-5 {
     flex: 0 0 41.666667%;
     max-width: 41.666667%;
   }
   .col-xl-6 {
     flex: 0 0 50%;
     max-width: 50%;
   }
   .col-xl-7 {
     flex: 0 0 58.333333%;
     max-width: 58.333333%;
   }
   .col-xl-8 {
     flex: 0 0 66.666667%;
     max-width: 66.666667%;
   }
   .col-xl-9 {
     flex: 0 0 75%;
     max-width: 75%;
   }
   .col-xl-10 {
     flex: 0 0 83.333333%;
     max-width: 83.333333%;
   }
   .col-xl-11 {
     flex: 0 0 91.666667%;
     max-width: 91.666667%;
   }
   .col-xl-12 {
     flex: 0 0 100%;
     max-width: 100%;
   }
   .order-xl-first {
     order: -1;
   }
   .order-xl-last {
     order: 13;
   }
   .order-xl-0 {
     order: 0;
   }
   .order-xl-1 {
     order: 1;
   }
   .order-xl-2 {
     order: 2;
   }
   .order-xl-3 {
     order: 3;
   }
   .order-xl-4 {
     order: 4;
   }
   .order-xl-5 {
     order: 5;
   }
   .order-xl-6 {
     order: 6;
   }
   .order-xl-7 {
     order: 7;
   }
   .order-xl-8 {
     order: 8;
   }
   .order-xl-9 {
     order: 9;
   }
   .order-xl-10 {
     order: 10;
   }
   .order-xl-11 {
     order: 11;
   }
   .order-xl-12 {
     order: 12;
   }
   .offset-xl-0 {
     margin-left: 0;
   }
   .offset-xl-1 {
     margin-left: 8.333333%;
   }
   .offset-xl-2 {
     margin-left: 16.666667%;
   }
   .offset-xl-3 {
     margin-left: 25%;
   }
   .offset-xl-4 {
     margin-left: 33.333333%;
   }
   .offset-xl-5 {
     margin-left: 41.666667%;
   }
   .offset-xl-6 {
     margin-left: 50%;
   }
   .offset-xl-7 {
     margin-left: 58.333333%;
   }
   .offset-xl-8 {
     margin-left: 66.666667%;
   }
   .offset-xl-9 {
     margin-left: 75%;
   }
   .offset-xl-10 {
     margin-left: 83.333333%;
   }
   .offset-xl-11 {
     margin-left: 91.666667%;
   }
 }
 
 .d-none {
   display: none !important;
 }
 
 .d-inline {
   display: inline !important;
 }
 
 .d-inline-block {
   display: inline-block !important;
 }
 
 .d-block {
   display: block !important;
 }
 
 .d-table {
   display: table !important;
 }
 
 .d-table-row {
   display: table-row !important;
 }
 
 .d-table-cell {
   display: table-cell !important;
 }
 
 .d-flex {
   display: flex !important;
 }
 
 .d-inline-flex {
   display: inline-flex !important;
 }
 
 @media (min-width: 576px) {
   .d-sm-none {
     display: none !important;
   }
   .d-sm-inline {
     display: inline !important;
   }
   .d-sm-inline-block {
     display: inline-block !important;
   }
   .d-sm-block {
     display: block !important;
   }
   .d-sm-table {
     display: table !important;
   }
   .d-sm-table-row {
     display: table-row !important;
   }
   .d-sm-table-cell {
     display: table-cell !important;
   }
   .d-sm-flex {
     display: flex !important;
   }
   .d-sm-inline-flex {
     display: inline-flex !important;
   }
 }
 
 @media (min-width: 768px) {
   .d-md-none {
     display: none !important;
   }
   .d-md-inline {
     display: inline !important;
   }
   .d-md-inline-block {
     display: inline-block !important;
   }
   .d-md-block {
     display: block !important;
   }
   .d-md-table {
     display: table !important;
   }
   .d-md-table-row {
     display: table-row !important;
   }
   .d-md-table-cell {
     display: table-cell !important;
   }
   .d-md-flex {
     display: flex !important;
   }
   .d-md-inline-flex {
     display: inline-flex !important;
   }
 }
 
 @media (min-width: 992px) {
   .d-lg-none {
     display: none !important;
   }
   .d-lg-inline {
     display: inline !important;
   }
   .d-lg-inline-block {
     display: inline-block !important;
   }
   .d-lg-block {
     display: block !important;
   }
   .d-lg-table {
     display: table !important;
   }
   .d-lg-table-row {
     display: table-row !important;
   }
   .d-lg-table-cell {
     display: table-cell !important;
   }
   .d-lg-flex {
     display: flex !important;
   }
   .d-lg-inline-flex {
     display: inline-flex !important;
   }
 }
 
 @media (min-width: 1200px) {
   .d-xl-none {
     display: none !important;
   }
   .d-xl-inline {
     display: inline !important;
   }
   .d-xl-inline-block {
     display: inline-block !important;
   }
   .d-xl-block {
     display: block !important;
   }
   .d-xl-table {
     display: table !important;
   }
   .d-xl-table-row {
     display: table-row !important;
   }
   .d-xl-table-cell {
     display: table-cell !important;
   }
   .d-xl-flex {
     display: flex !important;
   }
   .d-xl-inline-flex {
     display: inline-flex !important;
   }
 }
 
 @media print {
   .d-print-none {
     display: none !important;
   }
   .d-print-inline {
     display: inline !important;
   }
   .d-print-inline-block {
     display: inline-block !important;
   }
   .d-print-block {
     display: block !important;
   }
   .d-print-table {
     display: table !important;
   }
   .d-print-table-row {
     display: table-row !important;
   }
   .d-print-table-cell {
     display: table-cell !important;
   }
   .d-print-flex {
     display: flex !important;
   }
   .d-print-inline-flex {
     display: inline-flex !important;
   }
 }
 
 .flex-row {
   flex-direction: row !important;
 }
 
 .flex-column {
   flex-direction: column !important;
 }
 
 .flex-row-reverse {
   flex-direction: row-reverse !important;
 }
 
 .flex-column-reverse {
   flex-direction: column-reverse !important;
 }
 
 .flex-wrap {
   flex-wrap: wrap !important;
 }
 
 .flex-nowrap {
   flex-wrap: nowrap !important;
 }
 
 .flex-wrap-reverse {
   flex-wrap: wrap-reverse !important;
 }
 
 .flex-fill {
   flex: 1 1 auto !important;
 }
 
 .flex-grow-0 {
   flex-grow: 0 !important;
 }
 
 .flex-grow-1 {
   flex-grow: 1 !important;
 }
 
 .flex-shrink-0 {
   flex-shrink: 0 !important;
 }
 
 .flex-shrink-1 {
   flex-shrink: 1 !important;
 }
 
 .justify-content-start {
   justify-content: flex-start !important;
 }
 
 .justify-content-end {
   justify-content: flex-end !important;
 }
 
 .justify-content-center {
   justify-content: center !important;
 }
 
 .justify-content-between {
   justify-content: space-between !important;
 }
 
 .justify-content-around {
   justify-content: space-around !important;
 }
 
 .justify-content-evenly {
   justify-content: space-evenly !important;
 }
 
 .align-items-start {
   align-items: flex-start !important;
 }
 
 .align-items-end {
   align-items: flex-end !important;
 }
 
 .align-items-center {
   align-items: center !important;
 }
 
 .align-items-baseline {
   align-items: baseline !important;
 }
 
 .align-items-stretch {
   align-items: stretch !important;
 }
 
 .align-content-start {
   align-content: flex-start !important;
 }
 
 .align-content-end {
   align-content: flex-end !important;
 }
 
 .align-content-center {
   align-content: center !important;
 }
 
 .align-content-between {
   align-content: space-between !important;
 }
 
 .align-content-around {
   align-content: space-around !important;
 }
 
 .align-content-stretch {
   align-content: stretch !important;
 }
 
 .align-self-auto {
   align-self: auto !important;
 }
 
 .align-self-start {
   align-self: flex-start !important;
 }
 
 .align-self-end {
   align-self: flex-end !important;
 }
 
 .align-self-center {
   align-self: center !important;
 }
 
 .align-self-baseline {
   align-self: baseline !important;
 }
 
 .align-self-stretch {
   align-self: stretch !important;
 }
 
 @media (min-width: 576px) {
   .flex-sm-row {
     flex-direction: row !important;
   }
   .flex-sm-column {
     flex-direction: column !important;
   }
   .flex-sm-row-reverse {
     flex-direction: row-reverse !important;
   }
   .flex-sm-column-reverse {
     flex-direction: column-reverse !important;
   }
   .flex-sm-wrap {
     flex-wrap: wrap !important;
   }
   .flex-sm-nowrap {
     flex-wrap: nowrap !important;
   }
   .flex-sm-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }
   .flex-sm-fill {
     flex: 1 1 auto !important;
   }
   .flex-sm-grow-0 {
     flex-grow: 0 !important;
   }
   .flex-sm-grow-1 {
     flex-grow: 1 !important;
   }
   .flex-sm-shrink-0 {
     flex-shrink: 0 !important;
   }
   .flex-sm-shrink-1 {
     flex-shrink: 1 !important;
   }
   .justify-content-sm-start {
     justify-content: flex-start !important;
   }
   .justify-content-sm-end {
     justify-content: flex-end !important;
   }
   .justify-content-sm-center {
     justify-content: center !important;
   }
   .justify-content-sm-between {
     justify-content: space-between !important;
   }
   .justify-content-sm-around {
     justify-content: space-around !important;
   }
   .align-items-sm-start {
     align-items: flex-start !important;
   }
   .align-items-sm-end {
     align-items: flex-end !important;
   }
   .align-items-sm-center {
     align-items: center !important;
   }
   .align-items-sm-baseline {
     align-items: baseline !important;
   }
   .align-items-sm-stretch {
     align-items: stretch !important;
   }
   .align-content-sm-start {
     align-content: flex-start !important;
   }
   .align-content-sm-end {
     align-content: flex-end !important;
   }
   .align-content-sm-center {
     align-content: center !important;
   }
   .align-content-sm-between {
     align-content: space-between !important;
   }
   .align-content-sm-around {
     align-content: space-around !important;
   }
   .align-content-sm-stretch {
     align-content: stretch !important;
   }
   .align-self-sm-auto {
     align-self: auto !important;
   }
   .align-self-sm-start {
     align-self: flex-start !important;
   }
   .align-self-sm-end {
     align-self: flex-end !important;
   }
   .align-self-sm-center {
     align-self: center !important;
   }
   .align-self-sm-baseline {
     align-self: baseline !important;
   }
   .align-self-sm-stretch {
     align-self: stretch !important;
   }
 }
 
 @media (min-width: 768px) {
   .flex-md-row {
     flex-direction: row !important;
   }
   .flex-md-column {
     flex-direction: column !important;
   }
   .flex-md-row-reverse {
     flex-direction: row-reverse !important;
   }
   .flex-md-column-reverse {
     flex-direction: column-reverse !important;
   }
   .flex-md-wrap {
     flex-wrap: wrap !important;
   }
   .flex-md-nowrap {
     flex-wrap: nowrap !important;
   }
   .flex-md-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }
   .flex-md-fill {
     flex: 1 1 auto !important;
   }
   .flex-md-grow-0 {
     flex-grow: 0 !important;
   }
   .flex-md-grow-1 {
     flex-grow: 1 !important;
   }
   .flex-md-shrink-0 {
     flex-shrink: 0 !important;
   }
   .flex-md-shrink-1 {
     flex-shrink: 1 !important;
   }
   .justify-content-md-start {
     justify-content: flex-start !important;
   }
   .justify-content-md-end {
     justify-content: flex-end !important;
   }
   .justify-content-md-center {
     justify-content: center !important;
   }
   .justify-content-md-between {
     justify-content: space-between !important;
   }
   .justify-content-md-around {
     justify-content: space-around !important;
   }
   .align-items-md-start {
     align-items: flex-start !important;
   }
   .align-items-md-end {
     align-items: flex-end !important;
   }
   .align-items-md-center {
     align-items: center !important;
   }
   .align-items-md-baseline {
     align-items: baseline !important;
   }
   .align-items-md-stretch {
     align-items: stretch !important;
   }
   .align-content-md-start {
     align-content: flex-start !important;
   }
   .align-content-md-end {
     align-content: flex-end !important;
   }
   .align-content-md-center {
     align-content: center !important;
   }
   .align-content-md-between {
     align-content: space-between !important;
   }
   .align-content-md-around {
     align-content: space-around !important;
   }
   .align-content-md-stretch {
     align-content: stretch !important;
   }
   .align-self-md-auto {
     align-self: auto !important;
   }
   .align-self-md-start {
     align-self: flex-start !important;
   }
   .align-self-md-end {
     align-self: flex-end !important;
   }
   .align-self-md-center {
     align-self: center !important;
   }
   .align-self-md-baseline {
     align-self: baseline !important;
   }
   .align-self-md-stretch {
     align-self: stretch !important;
   }
 }
 
 @media (min-width: 992px) {
   .flex-lg-row {
     flex-direction: row !important;
   }
   .flex-lg-column {
     flex-direction: column !important;
   }
   .flex-lg-row-reverse {
     flex-direction: row-reverse !important;
   }
   .flex-lg-column-reverse {
     flex-direction: column-reverse !important;
   }
   .flex-lg-wrap {
     flex-wrap: wrap !important;
   }
   .flex-lg-nowrap {
     flex-wrap: nowrap !important;
   }
   .flex-lg-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }
   .flex-lg-fill {
     flex: 1 1 auto !important;
   }
   .flex-lg-grow-0 {
     flex-grow: 0 !important;
   }
   .flex-lg-grow-1 {
     flex-grow: 1 !important;
   }
   .flex-lg-shrink-0 {
     flex-shrink: 0 !important;
   }
   .flex-lg-shrink-1 {
     flex-shrink: 1 !important;
   }
   .justify-content-lg-start {
     justify-content: flex-start !important;
   }
   .justify-content-lg-end {
     justify-content: flex-end !important;
   }
   .justify-content-lg-center {
     justify-content: center !important;
   }
   .justify-content-lg-between {
     justify-content: space-between !important;
   }
   .justify-content-lg-around {
     justify-content: space-around !important;
   }
   .align-items-lg-start {
     align-items: flex-start !important;
   }
   .align-items-lg-end {
     align-items: flex-end !important;
   }
   .align-items-lg-center {
     align-items: center !important;
   }
   .align-items-lg-baseline {
     align-items: baseline !important;
   }
   .align-items-lg-stretch {
     align-items: stretch !important;
   }
   .align-content-lg-start {
     align-content: flex-start !important;
   }
   .align-content-lg-end {
     align-content: flex-end !important;
   }
   .align-content-lg-center {
     align-content: center !important;
   }
   .align-content-lg-between {
     align-content: space-between !important;
   }
   .align-content-lg-around {
     align-content: space-around !important;
   }
   .align-content-lg-stretch {
     align-content: stretch !important;
   }
   .align-self-lg-auto {
     align-self: auto !important;
   }
   .align-self-lg-start {
     align-self: flex-start !important;
   }
   .align-self-lg-end {
     align-self: flex-end !important;
   }
   .align-self-lg-center {
     align-self: center !important;
   }
   .align-self-lg-baseline {
     align-self: baseline !important;
   }
   .align-self-lg-stretch {
     align-self: stretch !important;
   }
 }
 
 @media (min-width: 1200px) {
   .flex-xl-row {
     flex-direction: row !important;
   }
   .flex-xl-column {
     flex-direction: column !important;
   }
   .flex-xl-row-reverse {
     flex-direction: row-reverse !important;
   }
   .flex-xl-column-reverse {
     flex-direction: column-reverse !important;
   }
   .flex-xl-wrap {
     flex-wrap: wrap !important;
   }
   .flex-xl-nowrap {
     flex-wrap: nowrap !important;
   }
   .flex-xl-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }
   .flex-xl-fill {
     flex: 1 1 auto !important;
   }
   .flex-xl-grow-0 {
     flex-grow: 0 !important;
   }
   .flex-xl-grow-1 {
     flex-grow: 1 !important;
   }
   .flex-xl-shrink-0 {
     flex-shrink: 0 !important;
   }
   .flex-xl-shrink-1 {
     flex-shrink: 1 !important;
   }
   .justify-content-xl-start {
     justify-content: flex-start !important;
   }
   .justify-content-xl-end {
     justify-content: flex-end !important;
   }
   .justify-content-xl-center {
     justify-content: center !important;
   }
   .justify-content-xl-between {
     justify-content: space-between !important;
   }
   .justify-content-xl-around {
     justify-content: space-around !important;
   }
   .align-items-xl-start {
     align-items: flex-start !important;
   }
   .align-items-xl-end {
     align-items: flex-end !important;
   }
   .align-items-xl-center {
     align-items: center !important;
   }
   .align-items-xl-baseline {
     align-items: baseline !important;
   }
   .align-items-xl-stretch {
     align-items: stretch !important;
   }
   .align-content-xl-start {
     align-content: flex-start !important;
   }
   .align-content-xl-end {
     align-content: flex-end !important;
   }
   .align-content-xl-center {
     align-content: center !important;
   }
   .align-content-xl-between {
     align-content: space-between !important;
   }
   .align-content-xl-around {
     align-content: space-around !important;
   }
   .align-content-xl-stretch {
     align-content: stretch !important;
   }
   .align-self-xl-auto {
     align-self: auto !important;
   }
   .align-self-xl-start {
     align-self: flex-start !important;
   }
   .align-self-xl-end {
     align-self: flex-end !important;
   }
   .align-self-xl-center {
     align-self: center !important;
   }
   .align-self-xl-baseline {
     align-self: baseline !important;
   }
   .align-self-xl-stretch {
     align-self: stretch !important;
   }
 }
 
 .m-0 {
   margin: 0 !important;
 }
 
 .mt-0,
 .my-0 {
   margin-top: 0 !important;
 }
 
 .mr-0,
 .mx-0 {
   margin-right: 0 !important;
 }
 
 .mb-0,
 .my-0 {
   margin-bottom: 0 !important;
 }
 
 .ml-0,
 .mx-0 {
   margin-left: 0 !important;
 }
 
 .m-1 {
   margin: 0.25rem !important;
 }
 
 .mt-1,
 .my-1 {
   margin-top: 0.25rem !important;
 }
 
 .mr-1,
 .mx-1 {
   margin-right: 0.25rem !important;
 }
 
 .mb-1,
 .my-1 {
   margin-bottom: 0.25rem !important;
 }
 
 .ml-1,
 .mx-1 {
   margin-left: 0.25rem !important;
 }
 
 .m-2 {
   margin: 0.5rem !important;
 }
 
 .mt-2,
 .my-2 {
   margin-top: 0.5rem !important;
 }
 
 .mr-2,
 .mx-2 {
   margin-right: 0.5rem !important;
 }
 
 .mb-2,
 .my-2 {
   margin-bottom: 0.5rem !important;
 }
 
 .ml-2,
 .mx-2 {
   margin-left: 0.5rem !important;
 }
 
 .m-3 {
   margin: 1rem !important;
 }
 
 .mt-3,
 .my-3 {
   margin-top: 1rem !important;
 }
 
 .mr-3,
 .mx-3 {
   margin-right: 1rem !important;
 }
 
 .mb-3,
 .my-3 {
   margin-bottom: 1rem !important;
 }
 
 .ml-3,
 .mx-3 {
   margin-left: 1rem !important;
 }
 
 .m-4 {
   margin: 1.5rem !important;
 }
 
 .mt-4,
 .my-4 {
   margin-top: 1.5rem !important;
 }
 
 .mr-4,
 .mx-4 {
   margin-right: 1.5rem !important;
 }
 
 .mb-4,
 .my-4 {
   margin-bottom: 1.5rem !important;
 }
 
 .ml-4,
 .mx-4 {
   margin-left: 1.5rem !important;
 }
 
 .m-5 {
   margin: 3rem !important;
 }
 
 .mt-5,
 .my-5 {
   margin-top: 3rem !important;
 }
 
 .mr-5,
 .mx-5 {
   margin-right: 3rem !important;
 }
 
 .mb-5,
 .my-5 {
   margin-bottom: 3rem !important;
 }
 
 .ml-5,
 .mx-5 {
   margin-left: 3rem !important;
 }
 
 .p-0 {
   padding: 0 !important;
 }
 
 .pt-0,
 .py-0 {
   padding-top: 0 !important;
 }
 
 .pr-0,
 .px-0 {
   padding-right: 0 !important;
 }
 
 .pb-0,
 .py-0 {
   padding-bottom: 0 !important;
 }
 
 .pl-0,
 .px-0 {
   padding-left: 0 !important;
 }
 
 .p-1 {
   padding: 0.25rem !important;
 }
 
 .pt-1,
 .py-1 {
   padding-top: 0.25rem !important;
 }
 
 .pr-1,
 .px-1 {
   padding-right: 0.25rem !important;
 }
 
 .pb-1,
 .py-1 {
   padding-bottom: 0.25rem !important;
 }
 
 .pl-1,
 .px-1 {
   padding-left: 0.25rem !important;
 }
 
 .p-2 {
   padding: 0.5rem !important;
 }
 
 .pt-2,
 .py-2 {
   padding-top: 0.5rem !important;
 }
 
 .pr-2,
 .px-2 {
   padding-right: 0.5rem !important;
 }
 
 .pb-2,
 .py-2 {
   padding-bottom: 0.5rem !important;
 }
 
 .pl-2,
 .px-2 {
   padding-left: 0.5rem !important;
 }
 
 .p-3 {
   padding: 1rem !important;
 }
 
 .pt-3,
 .py-3 {
   padding-top: 1rem !important;
 }
 
 .pr-3,
 .px-3 {
   padding-right: 1rem !important;
 }
 
 .pb-3,
 .py-3 {
   padding-bottom: 1rem !important;
 }
 
 .pl-3,
 .px-3 {
   padding-left: 1rem !important;
 }
 
 .p-4 {
   padding: 1.5rem !important;
 }
 
 .pt-4,
 .py-4 {
   padding-top: 1.5rem !important;
 }
 
 .pr-4,
 .px-4 {
   padding-right: 1.5rem !important;
 }
 
 .pb-4,
 .py-4 {
   padding-bottom: 1.5rem !important;
 }
 
 .pl-4,
 .px-4 {
   padding-left: 1.5rem !important;
 }
 
 .p-5 {
   padding: 3rem !important;
 }
 
 .pt-5,
 .py-5 {
   padding-top: 3rem !important;
 }
 
 .pr-5,
 .px-5 {
   padding-right: 3rem !important;
 }
 
 .pb-5,
 .py-5 {
   padding-bottom: 3rem !important;
 }
 
 .pl-5,
 .px-5 {
   padding-left: 3rem !important;
 }
 
 .m-n1 {
   margin: -0.25rem !important;
 }
 
 .mt-n1,
 .my-n1 {
   margin-top: -0.25rem !important;
 }
 
 .mr-n1,
 .mx-n1 {
   margin-right: -0.25rem !important;
 }
 
 .mb-n1,
 .my-n1 {
   margin-bottom: -0.25rem !important;
 }
 
 .ml-n1,
 .mx-n1 {
   margin-left: -0.25rem !important;
 }
 
 .m-n2 {
   margin: -0.5rem !important;
 }
 
 .mt-n2,
 .my-n2 {
   margin-top: -0.5rem !important;
 }
 
 .mr-n2,
 .mx-n2 {
   margin-right: -0.5rem !important;
 }
 
 .mb-n2,
 .my-n2 {
   margin-bottom: -0.5rem !important;
 }
 
 .ml-n2,
 .mx-n2 {
   margin-left: -0.5rem !important;
 }
 
 .m-n3 {
   margin: -1rem !important;
 }
 
 .mt-n3,
 .my-n3 {
   margin-top: -1rem !important;
 }
 
 .mr-n3,
 .mx-n3 {
   margin-right: -1rem !important;
 }
 
 .mb-n3,
 .my-n3 {
   margin-bottom: -1rem !important;
 }
 
 .ml-n3,
 .mx-n3 {
   margin-left: -1rem !important;
 }
 
 .m-n4 {
   margin: -1.5rem !important;
 }
 
 .mt-n4,
 .my-n4 {
   margin-top: -1.5rem !important;
 }
 
 .mr-n4,
 .mx-n4 {
   margin-right: -1.5rem !important;
 }
 
 .mb-n4,
 .my-n4 {
   margin-bottom: -1.5rem !important;
 }
 
 .ml-n4,
 .mx-n4 {
   margin-left: -1.5rem !important;
 }
 
 .m-n5 {
   margin: -3rem !important;
 }
 
 .mt-n5,
 .my-n5 {
   margin-top: -3rem !important;
 }
 
 .mr-n5,
 .mx-n5 {
   margin-right: -3rem !important;
 }
 
 .mb-n5,
 .my-n5 {
   margin-bottom: -3rem !important;
 }
 
 .ml-n5,
 .mx-n5 {
   margin-left: -3rem !important;
 }
 
 .m-auto {
   margin: auto !important;
 }
 
 .mt-auto,
 .my-auto {
   margin-top: auto !important;
 }
 
 .mr-auto,
 .mx-auto {
   margin-right: auto !important;
 }
 
 .mb-auto,
 .my-auto {
   margin-bottom: auto !important;
 }
 
 .ml-auto,
 .mx-auto {
   margin-left: auto !important;
 }
 
 @media (min-width: 576px) {
   .m-sm-0 {
     margin: 0 !important;
   }
   .mt-sm-0,
   .my-sm-0 {
     margin-top: 0 !important;
   }
   .mr-sm-0,
   .mx-sm-0 {
     margin-right: 0 !important;
   }
   .mb-sm-0,
   .my-sm-0 {
     margin-bottom: 0 !important;
   }
   .ml-sm-0,
   .mx-sm-0 {
     margin-left: 0 !important;
   }
   .m-sm-1 {
     margin: 0.25rem !important;
   }
   .mt-sm-1,
   .my-sm-1 {
     margin-top: 0.25rem !important;
   }
   .mr-sm-1,
   .mx-sm-1 {
     margin-right: 0.25rem !important;
   }
   .mb-sm-1,
   .my-sm-1 {
     margin-bottom: 0.25rem !important;
   }
   .ml-sm-1,
   .mx-sm-1 {
     margin-left: 0.25rem !important;
   }
   .m-sm-2 {
     margin: 0.5rem !important;
   }
   .mt-sm-2,
   .my-sm-2 {
     margin-top: 0.5rem !important;
   }
   .mr-sm-2,
   .mx-sm-2 {
     margin-right: 0.5rem !important;
   }
   .mb-sm-2,
   .my-sm-2 {
     margin-bottom: 0.5rem !important;
   }
   .ml-sm-2,
   .mx-sm-2 {
     margin-left: 0.5rem !important;
   }
   .m-sm-3 {
     margin: 1rem !important;
   }
   .mt-sm-3,
   .my-sm-3 {
     margin-top: 1rem !important;
   }
   .mr-sm-3,
   .mx-sm-3 {
     margin-right: 1rem !important;
   }
   .mb-sm-3,
   .my-sm-3 {
     margin-bottom: 1rem !important;
   }
   .ml-sm-3,
   .mx-sm-3 {
     margin-left: 1rem !important;
   }
   .m-sm-4 {
     margin: 1.5rem !important;
   }
   .mt-sm-4,
   .my-sm-4 {
     margin-top: 1.5rem !important;
   }
   .mr-sm-4,
   .mx-sm-4 {
     margin-right: 1.5rem !important;
   }
   .mb-sm-4,
   .my-sm-4 {
     margin-bottom: 1.5rem !important;
   }
   .ml-sm-4,
   .mx-sm-4 {
     margin-left: 1.5rem !important;
   }
   .m-sm-5 {
     margin: 3rem !important;
   }
   .mt-sm-5,
   .my-sm-5 {
     margin-top: 3rem !important;
   }
   .mr-sm-5,
   .mx-sm-5 {
     margin-right: 3rem !important;
   }
   .mb-sm-5,
   .my-sm-5 {
     margin-bottom: 3rem !important;
   }
   .ml-sm-5,
   .mx-sm-5 {
     margin-left: 3rem !important;
   }
   .p-sm-0 {
     padding: 0 !important;
   }
   .pt-sm-0,
   .py-sm-0 {
     padding-top: 0 !important;
   }
   .pr-sm-0,
   .px-sm-0 {
     padding-right: 0 !important;
   }
   .pb-sm-0,
   .py-sm-0 {
     padding-bottom: 0 !important;
   }
   .pl-sm-0,
   .px-sm-0 {
     padding-left: 0 !important;
   }
   .p-sm-1 {
     padding: 0.25rem !important;
   }
   .pt-sm-1,
   .py-sm-1 {
     padding-top: 0.25rem !important;
   }
   .pr-sm-1,
   .px-sm-1 {
     padding-right: 0.25rem !important;
   }
   .pb-sm-1,
   .py-sm-1 {
     padding-bottom: 0.25rem !important;
   }
   .pl-sm-1,
   .px-sm-1 {
     padding-left: 0.25rem !important;
   }
   .p-sm-2 {
     padding: 0.5rem !important;
   }
   .pt-sm-2,
   .py-sm-2 {
     padding-top: 0.5rem !important;
   }
   .pr-sm-2,
   .px-sm-2 {
     padding-right: 0.5rem !important;
   }
   .pb-sm-2,
   .py-sm-2 {
     padding-bottom: 0.5rem !important;
   }
   .pl-sm-2,
   .px-sm-2 {
     padding-left: 0.5rem !important;
   }
   .p-sm-3 {
     padding: 1rem !important;
   }
   .pt-sm-3,
   .py-sm-3 {
     padding-top: 1rem !important;
   }
   .pr-sm-3,
   .px-sm-3 {
     padding-right: 1rem !important;
   }
   .pb-sm-3,
   .py-sm-3 {
     padding-bottom: 1rem !important;
   }
   .pl-sm-3,
   .px-sm-3 {
     padding-left: 1rem !important;
   }
   .p-sm-4 {
     padding: 1.5rem !important;
   }
   .pt-sm-4,
   .py-sm-4 {
     padding-top: 1.5rem !important;
   }
   .pr-sm-4,
   .px-sm-4 {
     padding-right: 1.5rem !important;
   }
   .pb-sm-4,
   .py-sm-4 {
     padding-bottom: 1.5rem !important;
   }
   .pl-sm-4,
   .px-sm-4 {
     padding-left: 1.5rem !important;
   }
   .p-sm-5 {
     padding: 3rem !important;
   }
   .pt-sm-5,
   .py-sm-5 {
     padding-top: 3rem !important;
   }
   .pr-sm-5,
   .px-sm-5 {
     padding-right: 3rem !important;
   }
   .pb-sm-5,
   .py-sm-5 {
     padding-bottom: 3rem !important;
   }
   .pl-sm-5,
   .px-sm-5 {
     padding-left: 3rem !important;
   }
   .m-sm-n1 {
     margin: -0.25rem !important;
   }
   .mt-sm-n1,
   .my-sm-n1 {
     margin-top: -0.25rem !important;
   }
   .mr-sm-n1,
   .mx-sm-n1 {
     margin-right: -0.25rem !important;
   }
   .mb-sm-n1,
   .my-sm-n1 {
     margin-bottom: -0.25rem !important;
   }
   .ml-sm-n1,
   .mx-sm-n1 {
     margin-left: -0.25rem !important;
   }
   .m-sm-n2 {
     margin: -0.5rem !important;
   }
   .mt-sm-n2,
   .my-sm-n2 {
     margin-top: -0.5rem !important;
   }
   .mr-sm-n2,
   .mx-sm-n2 {
     margin-right: -0.5rem !important;
   }
   .mb-sm-n2,
   .my-sm-n2 {
     margin-bottom: -0.5rem !important;
   }
   .ml-sm-n2,
   .mx-sm-n2 {
     margin-left: -0.5rem !important;
   }
   .m-sm-n3 {
     margin: -1rem !important;
   }
   .mt-sm-n3,
   .my-sm-n3 {
     margin-top: -1rem !important;
   }
   .mr-sm-n3,
   .mx-sm-n3 {
     margin-right: -1rem !important;
   }
   .mb-sm-n3,
   .my-sm-n3 {
     margin-bottom: -1rem !important;
   }
   .ml-sm-n3,
   .mx-sm-n3 {
     margin-left: -1rem !important;
   }
   .m-sm-n4 {
     margin: -1.5rem !important;
   }
   .mt-sm-n4,
   .my-sm-n4 {
     margin-top: -1.5rem !important;
   }
   .mr-sm-n4,
   .mx-sm-n4 {
     margin-right: -1.5rem !important;
   }
   .mb-sm-n4,
   .my-sm-n4 {
     margin-bottom: -1.5rem !important;
   }
   .ml-sm-n4,
   .mx-sm-n4 {
     margin-left: -1.5rem !important;
   }
   .m-sm-n5 {
     margin: -3rem !important;
   }
   .mt-sm-n5,
   .my-sm-n5 {
     margin-top: -3rem !important;
   }
   .mr-sm-n5,
   .mx-sm-n5 {
     margin-right: -3rem !important;
   }
   .mb-sm-n5,
   .my-sm-n5 {
     margin-bottom: -3rem !important;
   }
   .ml-sm-n5,
   .mx-sm-n5 {
     margin-left: -3rem !important;
   }
   .m-sm-auto {
     margin: auto !important;
   }
   .mt-sm-auto,
   .my-sm-auto {
     margin-top: auto !important;
   }
   .mr-sm-auto,
   .mx-sm-auto {
     margin-right: auto !important;
   }
   .mb-sm-auto,
   .my-sm-auto {
     margin-bottom: auto !important;
   }
   .ml-sm-auto,
   .mx-sm-auto {
     margin-left: auto !important;
   }
 }
 
 @media (min-width: 768px) {
   .m-md-0 {
     margin: 0 !important;
   }
   .mt-md-0,
   .my-md-0 {
     margin-top: 0 !important;
   }
   .mr-md-0,
   .mx-md-0 {
     margin-right: 0 !important;
   }
   .mb-md-0,
   .my-md-0 {
     margin-bottom: 0 !important;
   }
   .ml-md-0,
   .mx-md-0 {
     margin-left: 0 !important;
   }
   .m-md-1 {
     margin: 0.25rem !important;
   }
   .mt-md-1,
   .my-md-1 {
     margin-top: 0.25rem !important;
   }
   .mr-md-1,
   .mx-md-1 {
     margin-right: 0.25rem !important;
   }
   .mb-md-1,
   .my-md-1 {
     margin-bottom: 0.25rem !important;
   }
   .ml-md-1,
   .mx-md-1 {
     margin-left: 0.25rem !important;
   }
   .m-md-2 {
     margin: 0.5rem !important;
   }
   .mt-md-2,
   .my-md-2 {
     margin-top: 0.5rem !important;
   }
   .mr-md-2,
   .mx-md-2 {
     margin-right: 0.5rem !important;
   }
   .mb-md-2,
   .my-md-2 {
     margin-bottom: 0.5rem !important;
   }
   .ml-md-2,
   .mx-md-2 {
     margin-left: 0.5rem !important;
   }
   .m-md-3 {
     margin: 1rem !important;
   }
   .mt-md-3,
   .my-md-3 {
     margin-top: 1rem !important;
   }
   .mr-md-3,
   .mx-md-3 {
     margin-right: 1rem !important;
   }
   .mb-md-3,
   .my-md-3 {
     margin-bottom: 1rem !important;
   }
   .ml-md-3,
   .mx-md-3 {
     margin-left: 1rem !important;
   }
   .m-md-4 {
     margin: 1.5rem !important;
   }
   .mt-md-4,
   .my-md-4 {
     margin-top: 1.5rem !important;
   }
   .mr-md-4,
   .mx-md-4 {
     margin-right: 1.5rem !important;
   }
   .mb-md-4,
   .my-md-4 {
     margin-bottom: 1.5rem !important;
   }
   .ml-md-4,
   .mx-md-4 {
     margin-left: 1.5rem !important;
   }
   .m-md-5 {
     margin: 3rem !important;
   }
   .mt-md-5,
   .my-md-5 {
     margin-top: 3rem !important;
   }
   .mr-md-5,
   .mx-md-5 {
     margin-right: 3rem !important;
   }
   .mb-md-5,
   .my-md-5 {
     margin-bottom: 3rem !important;
   }
   .ml-md-5,
   .mx-md-5 {
     margin-left: 3rem !important;
   }
   .p-md-0 {
     padding: 0 !important;
   }
   .pt-md-0,
   .py-md-0 {
     padding-top: 0 !important;
   }
   .pr-md-0,
   .px-md-0 {
     padding-right: 0 !important;
   }
   .pb-md-0,
   .py-md-0 {
     padding-bottom: 0 !important;
   }
   .pl-md-0,
   .px-md-0 {
     padding-left: 0 !important;
   }
   .p-md-1 {
     padding: 0.25rem !important;
   }
   .pt-md-1,
   .py-md-1 {
     padding-top: 0.25rem !important;
   }
   .pr-md-1,
   .px-md-1 {
     padding-right: 0.25rem !important;
   }
   .pb-md-1,
   .py-md-1 {
     padding-bottom: 0.25rem !important;
   }
   .pl-md-1,
   .px-md-1 {
     padding-left: 0.25rem !important;
   }
   .p-md-2 {
     padding: 0.5rem !important;
   }
   .pt-md-2,
   .py-md-2 {
     padding-top: 0.5rem !important;
   }
   .pr-md-2,
   .px-md-2 {
     padding-right: 0.5rem !important;
   }
   .pb-md-2,
   .py-md-2 {
     padding-bottom: 0.5rem !important;
   }
   .pl-md-2,
   .px-md-2 {
     padding-left: 0.5rem !important;
   }
   .p-md-3 {
     padding: 1rem !important;
   }
   .pt-md-3,
   .py-md-3 {
     padding-top: 1rem !important;
   }
   .pr-md-3,
   .px-md-3 {
     padding-right: 1rem !important;
   }
   .pb-md-3,
   .py-md-3 {
     padding-bottom: 1rem !important;
   }
   .pl-md-3,
   .px-md-3 {
     padding-left: 1rem !important;
   }
   .p-md-4 {
     padding: 1.5rem !important;
   }
   .pt-md-4,
   .py-md-4 {
     padding-top: 1.5rem !important;
   }
   .pr-md-4,
   .px-md-4 {
     padding-right: 1.5rem !important;
   }
   .pb-md-4,
   .py-md-4 {
     padding-bottom: 1.5rem !important;
   }
   .pl-md-4,
   .px-md-4 {
     padding-left: 1.5rem !important;
   }
   .p-md-5 {
     padding: 3rem !important;
   }
   .pt-md-5,
   .py-md-5 {
     padding-top: 3rem !important;
   }
   .pr-md-5,
   .px-md-5 {
     padding-right: 3rem !important;
   }
   .pb-md-5,
   .py-md-5 {
     padding-bottom: 3rem !important;
   }
   .pl-md-5,
   .px-md-5 {
     padding-left: 3rem !important;
   }
   .m-md-n1 {
     margin: -0.25rem !important;
   }
   .mt-md-n1,
   .my-md-n1 {
     margin-top: -0.25rem !important;
   }
   .mr-md-n1,
   .mx-md-n1 {
     margin-right: -0.25rem !important;
   }
   .mb-md-n1,
   .my-md-n1 {
     margin-bottom: -0.25rem !important;
   }
   .ml-md-n1,
   .mx-md-n1 {
     margin-left: -0.25rem !important;
   }
   .m-md-n2 {
     margin: -0.5rem !important;
   }
   .mt-md-n2,
   .my-md-n2 {
     margin-top: -0.5rem !important;
   }
   .mr-md-n2,
   .mx-md-n2 {
     margin-right: -0.5rem !important;
   }
   .mb-md-n2,
   .my-md-n2 {
     margin-bottom: -0.5rem !important;
   }
   .ml-md-n2,
   .mx-md-n2 {
     margin-left: -0.5rem !important;
   }
   .m-md-n3 {
     margin: -1rem !important;
   }
   .mt-md-n3,
   .my-md-n3 {
     margin-top: -1rem !important;
   }
   .mr-md-n3,
   .mx-md-n3 {
     margin-right: -1rem !important;
   }
   .mb-md-n3,
   .my-md-n3 {
     margin-bottom: -1rem !important;
   }
   .ml-md-n3,
   .mx-md-n3 {
     margin-left: -1rem !important;
   }
   .m-md-n4 {
     margin: -1.5rem !important;
   }
   .mt-md-n4,
   .my-md-n4 {
     margin-top: -1.5rem !important;
   }
   .mr-md-n4,
   .mx-md-n4 {
     margin-right: -1.5rem !important;
   }
   .mb-md-n4,
   .my-md-n4 {
     margin-bottom: -1.5rem !important;
   }
   .ml-md-n4,
   .mx-md-n4 {
     margin-left: -1.5rem !important;
   }
   .m-md-n5 {
     margin: -3rem !important;
   }
   .mt-md-n5,
   .my-md-n5 {
     margin-top: -3rem !important;
   }
   .mr-md-n5,
   .mx-md-n5 {
     margin-right: -3rem !important;
   }
   .mb-md-n5,
   .my-md-n5 {
     margin-bottom: -3rem !important;
   }
   .ml-md-n5,
   .mx-md-n5 {
     margin-left: -3rem !important;
   }
   .m-md-auto {
     margin: auto !important;
   }
   .mt-md-auto,
   .my-md-auto {
     margin-top: auto !important;
   }
   .mr-md-auto,
   .mx-md-auto {
     margin-right: auto !important;
   }
   .mb-md-auto,
   .my-md-auto {
     margin-bottom: auto !important;
   }
   .ml-md-auto,
   .mx-md-auto {
     margin-left: auto !important;
   }
 }
 
 @media (min-width: 992px) {
   .m-lg-0 {
     margin: 0 !important;
   }
   .mt-lg-0,
   .my-lg-0 {
     margin-top: 0 !important;
   }
   .mr-lg-0,
   .mx-lg-0 {
     margin-right: 0 !important;
   }
   .mb-lg-0,
   .my-lg-0 {
     margin-bottom: 0 !important;
   }
   .ml-lg-0,
   .mx-lg-0 {
     margin-left: 0 !important;
   }
   .m-lg-1 {
     margin: 0.25rem !important;
   }
   .mt-lg-1,
   .my-lg-1 {
     margin-top: 0.25rem !important;
   }
   .mr-lg-1,
   .mx-lg-1 {
     margin-right: 0.25rem !important;
   }
   .mb-lg-1,
   .my-lg-1 {
     margin-bottom: 0.25rem !important;
   }
   .ml-lg-1,
   .mx-lg-1 {
     margin-left: 0.25rem !important;
   }
   .m-lg-2 {
     margin: 0.5rem !important;
   }
   .mt-lg-2,
   .my-lg-2 {
     margin-top: 0.5rem !important;
   }
   .mr-lg-2,
   .mx-lg-2 {
     margin-right: 0.5rem !important;
   }
   .mb-lg-2,
   .my-lg-2 {
     margin-bottom: 0.5rem !important;
   }
   .ml-lg-2,
   .mx-lg-2 {
     margin-left: 0.5rem !important;
   }
   .m-lg-3 {
     margin: 1rem !important;
   }
   .mt-lg-3,
   .my-lg-3 {
     margin-top: 1rem !important;
   }
   .mr-lg-3,
   .mx-lg-3 {
     margin-right: 1rem !important;
   }
   .mb-lg-3,
   .my-lg-3 {
     margin-bottom: 1rem !important;
   }
   .ml-lg-3,
   .mx-lg-3 {
     margin-left: 1rem !important;
   }
   .m-lg-4 {
     margin: 1.5rem !important;
   }
   .mt-lg-4,
   .my-lg-4 {
     margin-top: 1.5rem !important;
   }
   .mr-lg-4,
   .mx-lg-4 {
     margin-right: 1.5rem !important;
   }
   .mb-lg-4,
   .my-lg-4 {
     margin-bottom: 1.5rem !important;
   }
   .ml-lg-4,
   .mx-lg-4 {
     margin-left: 1.5rem !important;
   }
   .m-lg-5 {
     margin: 3rem !important;
   }
   .mt-lg-5,
   .my-lg-5 {
     margin-top: 3rem !important;
   }
   .mr-lg-5,
   .mx-lg-5 {
     margin-right: 3rem !important;
   }
   .mb-lg-5,
   .my-lg-5 {
     margin-bottom: 3rem !important;
   }
   .ml-lg-5,
   .mx-lg-5 {
     margin-left: 3rem !important;
   }
   .p-lg-0 {
     padding: 0 !important;
   }
   .pt-lg-0,
   .py-lg-0 {
     padding-top: 0 !important;
   }
   .pr-lg-0,
   .px-lg-0 {
     padding-right: 0 !important;
   }
   .pb-lg-0,
   .py-lg-0 {
     padding-bottom: 0 !important;
   }
   .pl-lg-0,
   .px-lg-0 {
     padding-left: 0 !important;
   }
   .p-lg-1 {
     padding: 0.25rem !important;
   }
   .pt-lg-1,
   .py-lg-1 {
     padding-top: 0.25rem !important;
   }
   .pr-lg-1,
   .px-lg-1 {
     padding-right: 0.25rem !important;
   }
   .pb-lg-1,
   .py-lg-1 {
     padding-bottom: 0.25rem !important;
   }
   .pl-lg-1,
   .px-lg-1 {
     padding-left: 0.25rem !important;
   }
   .p-lg-2 {
     padding: 0.5rem !important;
   }
   .pt-lg-2,
   .py-lg-2 {
     padding-top: 0.5rem !important;
   }
   .pr-lg-2,
   .px-lg-2 {
     padding-right: 0.5rem !important;
   }
   .pb-lg-2,
   .py-lg-2 {
     padding-bottom: 0.5rem !important;
   }
   .pl-lg-2,
   .px-lg-2 {
     padding-left: 0.5rem !important;
   }
   .p-lg-3 {
     padding: 1rem !important;
   }
   .pt-lg-3,
   .py-lg-3 {
     padding-top: 1rem !important;
   }
   .pr-lg-3,
   .px-lg-3 {
     padding-right: 1rem !important;
   }
   .pb-lg-3,
   .py-lg-3 {
     padding-bottom: 1rem !important;
   }
   .pl-lg-3,
   .px-lg-3 {
     padding-left: 1rem !important;
   }
   .p-lg-4 {
     padding: 1.5rem !important;
   }
   .pt-lg-4,
   .py-lg-4 {
     padding-top: 1.5rem !important;
   }
   .pr-lg-4,
   .px-lg-4 {
     padding-right: 1.5rem !important;
   }
   .pb-lg-4,
   .py-lg-4 {
     padding-bottom: 1.5rem !important;
   }
   .pl-lg-4,
   .px-lg-4 {
     padding-left: 1.5rem !important;
   }
   .p-lg-5 {
     padding: 3rem !important;
   }
   .pt-lg-5,
   .py-lg-5 {
     padding-top: 3rem !important;
   }
   .pr-lg-5,
   .px-lg-5 {
     padding-right: 3rem !important;
   }
   .pb-lg-5,
   .py-lg-5 {
     padding-bottom: 3rem !important;
   }
   .pl-lg-5,
   .px-lg-5 {
     padding-left: 3rem !important;
   }
   .m-lg-n1 {
     margin: -0.25rem !important;
   }
   .mt-lg-n1,
   .my-lg-n1 {
     margin-top: -0.25rem !important;
   }
   .mr-lg-n1,
   .mx-lg-n1 {
     margin-right: -0.25rem !important;
   }
   .mb-lg-n1,
   .my-lg-n1 {
     margin-bottom: -0.25rem !important;
   }
   .ml-lg-n1,
   .mx-lg-n1 {
     margin-left: -0.25rem !important;
   }
   .m-lg-n2 {
     margin: -0.5rem !important;
   }
   .mt-lg-n2,
   .my-lg-n2 {
     margin-top: -0.5rem !important;
   }
   .mr-lg-n2,
   .mx-lg-n2 {
     margin-right: -0.5rem !important;
   }
   .mb-lg-n2,
   .my-lg-n2 {
     margin-bottom: -0.5rem !important;
   }
   .ml-lg-n2,
   .mx-lg-n2 {
     margin-left: -0.5rem !important;
   }
   .m-lg-n3 {
     margin: -1rem !important;
   }
   .mt-lg-n3,
   .my-lg-n3 {
     margin-top: -1rem !important;
   }
   .mr-lg-n3,
   .mx-lg-n3 {
     margin-right: -1rem !important;
   }
   .mb-lg-n3,
   .my-lg-n3 {
     margin-bottom: -1rem !important;
   }
   .ml-lg-n3,
   .mx-lg-n3 {
     margin-left: -1rem !important;
   }
   .m-lg-n4 {
     margin: -1.5rem !important;
   }
   .mt-lg-n4,
   .my-lg-n4 {
     margin-top: -1.5rem !important;
   }
   .mr-lg-n4,
   .mx-lg-n4 {
     margin-right: -1.5rem !important;
   }
   .mb-lg-n4,
   .my-lg-n4 {
     margin-bottom: -1.5rem !important;
   }
   .ml-lg-n4,
   .mx-lg-n4 {
     margin-left: -1.5rem !important;
   }
   .m-lg-n5 {
     margin: -3rem !important;
   }
   .mt-lg-n5,
   .my-lg-n5 {
     margin-top: -3rem !important;
   }
   .mr-lg-n5,
   .mx-lg-n5 {
     margin-right: -3rem !important;
   }
   .mb-lg-n5,
   .my-lg-n5 {
     margin-bottom: -3rem !important;
   }
   .ml-lg-n5,
   .mx-lg-n5 {
     margin-left: -3rem !important;
   }
   .m-lg-auto {
     margin: auto !important;
   }
   .mt-lg-auto,
   .my-lg-auto {
     margin-top: auto !important;
   }
   .mr-lg-auto,
   .mx-lg-auto {
     margin-right: auto !important;
   }
   .mb-lg-auto,
   .my-lg-auto {
     margin-bottom: auto !important;
   }
   .ml-lg-auto,
   .mx-lg-auto {
     margin-left: auto !important;
   }
 }
 
 @media (min-width: 1200px) {
   .m-xl-0 {
     margin: 0 !important;
   }
   .mt-xl-0,
   .my-xl-0 {
     margin-top: 0 !important;
   }
   .mr-xl-0,
   .mx-xl-0 {
     margin-right: 0 !important;
   }
   .mb-xl-0,
   .my-xl-0 {
     margin-bottom: 0 !important;
   }
   .ml-xl-0,
   .mx-xl-0 {
     margin-left: 0 !important;
   }
   .m-xl-1 {
     margin: 0.25rem !important;
   }
   .mt-xl-1,
   .my-xl-1 {
     margin-top: 0.25rem !important;
   }
   .mr-xl-1,
   .mx-xl-1 {
     margin-right: 0.25rem !important;
   }
   .mb-xl-1,
   .my-xl-1 {
     margin-bottom: 0.25rem !important;
   }
   .ml-xl-1,
   .mx-xl-1 {
     margin-left: 0.25rem !important;
   }
   .m-xl-2 {
     margin: 0.5rem !important;
   }
   .mt-xl-2,
   .my-xl-2 {
     margin-top: 0.5rem !important;
   }
   .mr-xl-2,
   .mx-xl-2 {
     margin-right: 0.5rem !important;
   }
   .mb-xl-2,
   .my-xl-2 {
     margin-bottom: 0.5rem !important;
   }
   .ml-xl-2,
   .mx-xl-2 {
     margin-left: 0.5rem !important;
   }
   .m-xl-3 {
     margin: 1rem !important;
   }
   .mt-xl-3,
   .my-xl-3 {
     margin-top: 1rem !important;
   }
   .mr-xl-3,
   .mx-xl-3 {
     margin-right: 1rem !important;
   }
   .mb-xl-3,
   .my-xl-3 {
     margin-bottom: 1rem !important;
   }
   .ml-xl-3,
   .mx-xl-3 {
     margin-left: 1rem !important;
   }
   .m-xl-4 {
     margin: 1.5rem !important;
   }
   .mt-xl-4,
   .my-xl-4 {
     margin-top: 1.5rem !important;
   }
   .mr-xl-4,
   .mx-xl-4 {
     margin-right: 1.5rem !important;
   }
   .mb-xl-4,
   .my-xl-4 {
     margin-bottom: 1.5rem !important;
   }
   .ml-xl-4,
   .mx-xl-4 {
     margin-left: 1.5rem !important;
   }
   .m-xl-5 {
     margin: 3rem !important;
   }
   .mt-xl-5,
   .my-xl-5 {
     margin-top: 3rem !important;
   }
   .mr-xl-5,
   .mx-xl-5 {
     margin-right: 3rem !important;
   }
   .mb-xl-5,
   .my-xl-5 {
     margin-bottom: 3rem !important;
   }
   .ml-xl-5,
   .mx-xl-5 {
     margin-left: 3rem !important;
   }
   .p-xl-0 {
     padding: 0 !important;
   }
   .pt-xl-0,
   .py-xl-0 {
     padding-top: 0 !important;
   }
   .pr-xl-0,
   .px-xl-0 {
     padding-right: 0 !important;
   }
   .pb-xl-0,
   .py-xl-0 {
     padding-bottom: 0 !important;
   }
   .pl-xl-0,
   .px-xl-0 {
     padding-left: 0 !important;
   }
   .p-xl-1 {
     padding: 0.25rem !important;
   }
   .pt-xl-1,
   .py-xl-1 {
     padding-top: 0.25rem !important;
   }
   .pr-xl-1,
   .px-xl-1 {
     padding-right: 0.25rem !important;
   }
   .pb-xl-1,
   .py-xl-1 {
     padding-bottom: 0.25rem !important;
   }
   .pl-xl-1,
   .px-xl-1 {
     padding-left: 0.25rem !important;
   }
   .p-xl-2 {
     padding: 0.5rem !important;
   }
   .pt-xl-2,
   .py-xl-2 {
     padding-top: 0.5rem !important;
   }
   .pr-xl-2,
   .px-xl-2 {
     padding-right: 0.5rem !important;
   }
   .pb-xl-2,
   .py-xl-2 {
     padding-bottom: 0.5rem !important;
   }
   .pl-xl-2,
   .px-xl-2 {
     padding-left: 0.5rem !important;
   }
   .p-xl-3 {
     padding: 1rem !important;
   }
   .pt-xl-3,
   .py-xl-3 {
     padding-top: 1rem !important;
   }
   .pr-xl-3,
   .px-xl-3 {
     padding-right: 1rem !important;
   }
   .pb-xl-3,
   .py-xl-3 {
     padding-bottom: 1rem !important;
   }
   .pl-xl-3,
   .px-xl-3 {
     padding-left: 1rem !important;
   }
   .p-xl-4 {
     padding: 1.5rem !important;
   }
   .pt-xl-4,
   .py-xl-4 {
     padding-top: 1.5rem !important;
   }
   .pr-xl-4,
   .px-xl-4 {
     padding-right: 1.5rem !important;
   }
   .pb-xl-4,
   .py-xl-4 {
     padding-bottom: 1.5rem !important;
   }
   .pl-xl-4,
   .px-xl-4 {
     padding-left: 1.5rem !important;
   }
   .p-xl-5 {
     padding: 3rem !important;
   }
   .pt-xl-5,
   .py-xl-5 {
     padding-top: 3rem !important;
   }
   .pr-xl-5,
   .px-xl-5 {
     padding-right: 3rem !important;
   }
   .pb-xl-5,
   .py-xl-5 {
     padding-bottom: 3rem !important;
   }
   .pl-xl-5,
   .px-xl-5 {
     padding-left: 3rem !important;
   }
   .m-xl-n1 {
     margin: -0.25rem !important;
   }
   .mt-xl-n1,
   .my-xl-n1 {
     margin-top: -0.25rem !important;
   }
   .mr-xl-n1,
   .mx-xl-n1 {
     margin-right: -0.25rem !important;
   }
   .mb-xl-n1,
   .my-xl-n1 {
     margin-bottom: -0.25rem !important;
   }
   .ml-xl-n1,
   .mx-xl-n1 {
     margin-left: -0.25rem !important;
   }
   .m-xl-n2 {
     margin: -0.5rem !important;
   }
   .mt-xl-n2,
   .my-xl-n2 {
     margin-top: -0.5rem !important;
   }
   .mr-xl-n2,
   .mx-xl-n2 {
     margin-right: -0.5rem !important;
   }
   .mb-xl-n2,
   .my-xl-n2 {
     margin-bottom: -0.5rem !important;
   }
   .ml-xl-n2,
   .mx-xl-n2 {
     margin-left: -0.5rem !important;
   }
   .m-xl-n3 {
     margin: -1rem !important;
   }
   .mt-xl-n3,
   .my-xl-n3 {
     margin-top: -1rem !important;
   }
   .mr-xl-n3,
   .mx-xl-n3 {
     margin-right: -1rem !important;
   }
   .mb-xl-n3,
   .my-xl-n3 {
     margin-bottom: -1rem !important;
   }
   .ml-xl-n3,
   .mx-xl-n3 {
     margin-left: -1rem !important;
   }
   .m-xl-n4 {
     margin: -1.5rem !important;
   }
   .mt-xl-n4,
   .my-xl-n4 {
     margin-top: -1.5rem !important;
   }
   .mr-xl-n4,
   .mx-xl-n4 {
     margin-right: -1.5rem !important;
   }
   .mb-xl-n4,
   .my-xl-n4 {
     margin-bottom: -1.5rem !important;
   }
   .ml-xl-n4,
   .mx-xl-n4 {
     margin-left: -1.5rem !important;
   }
   .m-xl-n5 {
     margin: -3rem !important;
   }
   .mt-xl-n5,
   .my-xl-n5 {
     margin-top: -3rem !important;
   }
   .mr-xl-n5,
   .mx-xl-n5 {
     margin-right: -3rem !important;
   }
   .mb-xl-n5,
   .my-xl-n5 {
     margin-bottom: -3rem !important;
   }
   .ml-xl-n5,
   .mx-xl-n5 {
     margin-left: -3rem !important;
   }
   .m-xl-auto {
     margin: auto !important;
   }
   .mt-xl-auto,
   .my-xl-auto {
     margin-top: auto !important;
   }
   .mr-xl-auto,
   .mx-xl-auto {
     margin-right: auto !important;
   }
   .mb-xl-auto,
   .my-xl-auto {
     margin-bottom: auto !important;
   }
   .ml-xl-auto,
   .mx-xl-auto {
     margin-left: auto !important;
   }
 }
 /*# sourceMappingURL=bootstrap-grid.css.map */
 
.genres {
  overflow: auto;
  white-space: nowrap;
  width: 68%;
  /* height: 30px; */
}

.genres_box {
  border: 1px solid white;
  border-radius: 20px;
}

.rating_box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 10px;
  font-size: 13px;
}

.favourite_box {
  padding-top: 1px;
}

.icon_color_blue {
  color: #0ed2f7;
}
.icon_spacing {
  margin-top: 3px;
}

.cstm_downlink {
  padding: 0px;
  font-size: 16px;
  border: 1px solid rgb(24, 22, 22);
  border-radius: 30px;
  background-color: #0f131a;
  box-shadow: 8px 8px 6px -6px rgba(0, 0, 0, 0.75),
    -3px -3px 11px -6px rgba(105, 105, 105, 0.5);

  position: relative;
}

.new_item {
  position: absolute;
  top: 0;
  right: 5;
  margin-left: 5px;
  margin-top: 5px;
  border: 1px solid red;
  background-color: #f04f43;
  border-radius: 50%;
  padding: 3px;
}

.cstm_downlink:active {
  box-shadow: rgba(0, 0, 0, 0.5) 12px 12px 24px 0px inset,
    rgba(58, 58, 58, 0.376) -12px -12px 24px 0px inset;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 12px 12px 24px 0px inset,
    rgba(58, 58, 58, 0.376) -12px -12px 24px 0px inset;
}

.cstm_downlink:visited {
  box-shadow: rgba(0, 0, 0, 0.5) 12px 12px 24px 0px inset,
    rgba(58, 58, 58, 0.376) -12px -12px 24px 0px inset;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 12px 12px 24px 0px inset,
    rgba(58, 58, 58, 0.376) -12px -12px 24px 0px inset;
}

.cstm_downlink:focus {
  box-shadow: rgba(0, 0, 0, 0.5) 12px 12px 24px 0px inset,
    rgba(58, 58, 58, 0.376) -12px -12px 24px 0px inset;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 12px 12px 24px 0px inset,
    rgba(58, 58, 58, 0.376) -12px -12px 24px 0px inset;
}
.cstm_downlink_group {
  min-height: 200px;
}

.casts_img {
  overflow: auto;
  white-space: nowrap;

  /* height: 30px; */
}

.DetailsPage .rs-panel-heading,
.rs-panel-body {
  font-size: 16px !important;
  padding-bottom: 5px;
}

.rs-panel-heading {
  font-size: 20px !important;
  padding: 0.4rem !important;
}

.rs-panel-heading .rs-panel-title {
  font-size: 20px !important;
  padding-left: 2px;
}

.rs-panel-collapsible > .rs-panel-heading::before {
  position: absolute;
  font-family: "rsuite-icon-font";
  content: "\ea08";
  top: 10px;
  right: 20px;
  transition: transform 0.3s linear;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  cursor: pointer;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  cursor: pointer;
}

@media only screen and (min-width: 570px) {
  .video-responsive {
   
    padding-bottom: 0;
    display: block;
    height: 50vh;
    max-height: 50vh;
  }


}

@media only screen and (max-width: 570px) {
  .container-md {
  width: 100%;
   padding-right: 0;
   padding-left:0;
   margin-right: 0;
   margin-left: 0;
  }
}
 

.FooterPage {
  /* font-family: 'Concert One', cursive; */
  /* font-family: 'Roboto', sans-serif; */
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  display: block;
  height: 60px;
  width: 100%;
  background-color: #0f1218;
  box-shadow: -2px -2px 10px -6px rgba(105, 105, 105, 0.5);
 
}

.FooterPage_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* .copyright {
  font-family: "Concert One", cursive;
  font-weight: lighter;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.7);
} */

.copy_sign {
  font-family: "Roboto", sans-serif;
  color: rgba(255, 255, 255, 0.7);
}

.social_icons_group {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media only screen and (max-width: 769px) {
  .FooterPage {
    font-size: 14px;
  }

  .social_icons_group > * {
    margin: 0 6px;
  }
}

.custom_loading {
   position: fixed;
   top: 45%;
   left: 40%;
}


.custom_loading > ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.custom_loading > ul > li {
  list-style: none;
  color: #e0e4eb;
  font-size: 40px;
  letter-spacing: 8px;
  -webkit-animation: lighting 1.2s linear infinite;
          animation: lighting 1.2s linear infinite;
}

@-webkit-keyframes lighting {
  0% {
    color: #0f131a;
    text-shadow: none;
  }
  90% {
    color: #0f131a;
    text-shadow: none;
  }
  100% {
    color: #0f131a;
    text-shadow: 0 0 7px #ffffff, 0 0 50px #ffffff;
  }
}

@keyframes lighting {
  0% {
    color: #0f131a;
    text-shadow: none;
  }
  90% {
    color: #0f131a;
    text-shadow: none;
  }
  100% {
    color: #0f131a;
    text-shadow: 0 0 7px #ffffff, 0 0 50px #ffffff;
  }
}

.custom_loading > ul li:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.custom_loading > ul li:nth-child(2) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.custom_loading > ul li:nth-child(3) {
  -webkit-animation-delay: 0.2;
          animation-delay: 0.2;
}

.custom_loading > ul li:nth-child(4) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.custom_loading > ul li:nth-child(5) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.custom_loading > ul li:nth-child(6) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.custom_loading > ul li:nth-child(7) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.text_gradient {
  background-color: #3fd4cd;
  background-color: linear-gradient(to left, #b2fefa, #0ed2f7);
  background-size: 100%;
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.HomePage .rs-btn-ghost {
  border: none !important;
}

.btn_font_style {
  font-family: "Concert One", cursive;
  letter-spacing: 0.2px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.747);
  align-self: flex-end;
  margin-bottom: 2px;
  cursor: pointer;
}
.intro_heading_text {
  font-family: "Concert One", cursive;
  font-size: 25px;
}

.intro_heading_text2 {
  font-family: "Concert One", cursive;
  font-size: 18px;
  white-space: nowrap;
}

.svg_div {
  width: 50%;
  height: auto;
}
.svg_side_text {
  font: 20px;
  /* font-family: 'Concert One', cursive; */
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  flex: 1 1;
}
.content_heading_text {
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  white-space: nowrap;
}

.get_started_btn {
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.span_text {
  font-family: "Roboto", sans-serif;
  background-color: rgb(108, 23, 177);
  padding: 3px;
  border-radius: 7px;
}

.view_all_btn {
  border-radius: 30px !important;
}


.font_poppins {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
}

.explore_section {
  /* background-color: rgb(25,18,96); */
  background: linear-gradient(180deg, rgba(15,19,26,1) 39%, rgba(20,16,93,1) 100%);
}

.carousel_container {
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}


/* If you want to use a custom scrolling bar */

/* @media only screen and (min-width: 768px) {
  .carousel_container::-webkit-scrollbar {
    width: 4px; 
    height: 17px;
  }
  .carousel_container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.192); 
    border-radius: 30px;
  }
  .carousel_container::-webkit-scrollbar-thumb {
    background-color: rgb(23, 40, 48);
    border-radius: 20px; 
    padding: 20px;              
  }
} */


.swiper_container {
  height: 500px;
  background-color: red;
}
/* .swiper-button-prev,
.swiper-button-next {
  top: 42%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  padding: 30px;
  background-color: aliceblue;
  border-radius: 50%;
}

.swiper-button-prev {
}
.swiper-button-prev::after {
}

.swiper-button-next {

}
.swiper-button-next::after {
} */

.swiper-scrollbar-drag {
  /* background: linear-gradient(to left, #b2fefa, #0ed2f7); */
  background: linear-gradient(to right, #67fff7, #0fadcc);
}
.swiper-container-horizontal > .swiper-scrollbar {
  width: 95%;
  left: 3%;
}

