.card_body {
  text-align: center;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: rgba(8, 8, 8, 0.753);
  /* background-color: #22252b; */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.image_panel {
  position: relative;
  display: inline-block;
  width: 148px;
  border: 0;
}

.add_to_fav {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(54, 54, 54, 0.527);
  border-radius: 0px 6px 0px 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.MovieCard {
  width: 100%;
  height: auto;
}

