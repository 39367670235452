.CourseCard {
  width: 40%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  border: 1px solid #eeeeff;
  border-radius: 8px;
  overflow: hidden;
}

.img_container {
  width: 40px;
  height: 40px;
}

/* .view_all_btn {
   display: block;
   margin-top: -24px;
 }
  */
